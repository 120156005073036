module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alta Medicina Diagnóstica","short_name":"Alta","start_url":"/","background_color":"#beeaef","theme_color":"#007e8f","display":"minimal-ui","icon":"src/assets/images/alta-favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5b104f7995f233339acdc570f9232f89"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"crumbSeparator":" > ","useAutoGen":true,"autoGenHomeLabel":"Home","exclude":[],"crumbLabelUpdates":[],"useClassNames":true,"usePathPrefix":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-translate/gatsby-browser.js'),
      options: {"plugins":[],"googleApiKey":"AIzaSyA09L-sJgvQOy27zuaGshJlv6ST5LkdD6A","sourceLanguage":"pt-br","targetLanguages":[],"translateSlug":false,"translations":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K5SPXTB"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MV7HV2L"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
