exports.onRouteUpdate = ({ location, prevLocation }) => {
  const matchExamBackNavigation =
    (location.pathname === '/exames' || location.pathname === '/exames/') &&
    prevLocation &&
    /\/exames\//gi.test(prevLocation.pathname)
  const matchVaccineBackNavigation =
    (location.pathname === '/vacinas' || location.pathname === '/vacinas/') &&
    prevLocation &&
    /\/vacinas\//gi.test(prevLocation.pathname)

  if (matchExamBackNavigation || matchVaccineBackNavigation)
    localStorage.setItem('trigger-search', 'true')
}
